<template>
	<li
		class="tree__node"
		:class="{
			'tree__node--root': level === 0,
			[`tree__node--level-${level}`]: level > 0,
		}"
		role="treeitem">
		<template v-if="item.children?.length">
			<div class="tree__node__title">
				<v-tree-item :item="item" :level="level">
					<template v-if="slots.item" #item="slotProps">
						<slot name="item" v-bind="slotProps" />
					</template>
				</v-tree-item>
			</div>

			<ul class="tree__node__children" role="group">
				<v-tree-node
					v-for="(childItem, childItemIndex) in item.children"
					:key="childItemIndex"
					:item="childItem"
					:level="level + 1">
					<template v-if="slots.item" #item="slotProps">
						<slot name="item" v-bind="slotProps" />
					</template>
				</v-tree-node>
			</ul>
		</template>
		<template v-else>
			<v-tree-item :item="item" :level="level">
				<template v-if="slots.item" #item="slotProps">
					<slot name="item" v-bind="slotProps" />
				</template>
			</v-tree-item>
		</template>
	</li>
</template>

<script lang="ts" setup>
	import type { TreeItem } from './tree-item.vue'

	const props = defineProps<{
		item: TreeItem
		level?: number
	}>()

	const slots = useSlots()

	const level = computed(() => props.level ?? 0)
</script>

<style lang="scss">
	@layer components {
		.tree__node {
			&--root {
				&:not(:first-child) {
					margin-top: 20px;
				}

				> .tree__item,
				> .tree__node__title > .tree__item {
					color: $gray-900;
					font-weight: 700;
				}
			}

			[class*='tree__node--level'] {
				margin-top: 10px;
			}

			&__title {
				margin-bottom: 10px;
			}

			&__children {
				@include reset-list();
			}
		}
	}
</style>
