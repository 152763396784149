<template>
	<ul class="tree" role="tree">
		<slot />
	</ul>
</template>

<script lang="ts" setup></script>

<style lang="scss">
	@layer components {
		.tree {
			@include reset-list();

			color: $gray-500;
			font-size: $text-xs;
			font-weight: 500;
		}
	}
</style>
