<template>
	<component
		:is="component"
		v-bind="componentAttrs"
		class="tree__item"
		:class="{
			[`tree__item--level-${level}`]: level,
			'tree__item--active': item.active?.(),
		}"
		:style="{
			'--tree-item-offset': `${level * 10}px`,
		}"
		@click="onClick">
		<slot name="item" v-bind="{ item }">
			{{ item.title }}
		</slot>
	</component>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		item: TreeItem
		level?: number
	}>()

	const emit = defineEmits<{
		'tree:click': [TreeItem]
	}>()

	const level = computed(() => props.level ?? 0)

	const type = computed(() => {
		if (props.item.command) {
			return 'button'
		}

		if (props.item.link) {
			return props.item.link.startsWith('http') ? 'a' : 'NuxtLink'
		}

		return 'p'
	})

	const component = computed(() => {
		return type.value === 'NuxtLink' ? resolveComponent('NuxtLink') : type.value
	})

	const componentAttrs = computed(() => {
		switch (type.value) {
			case 'a':
				return {
					href: props.item.link,
					target: '_blank',
				}

			case 'button':
				return {
					type: 'button',
				}

			case 'NuxtLink':
				return {
					to: props.item.link,
					activeClass: 'tree__item--active',
					exactActiveClass: 'tree__item--exact-active',
				}
		}
	})

	function onClick() {
		emit('tree:click', props.item)
		props.item.command?.()
	}
</script>

<script lang="ts">
	export interface TreeItem {
		title: string
		link?: string
		children?: TreeItem[]
		active?: () => boolean
		command?: () => void
	}
</script>

<style lang="scss">
	@layer components {
		.tree__item {
			display: block;
			font-weight: 500;
			padding-left: var(--tree-item-offset, 0);

			&:is(a, button) {
				transition: 250ms ease;
				transition-property: color;

				&:hover {
					color: $gray-700;
				}
			}

			&--active,
			&--exact-active {
				color: $brand !important;
			}
		}
	}
</style>
